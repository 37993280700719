<template>
    <div class="progress" :style="{ height: height }">
        <div class="progress-bar"
             :class="[striped, animated]"
             :title="now + '/' + max"
             role="progressbar"
             :style="{ width: width + '%' }"
             :aria-valuenow="now"
             :aria-valuemin="min"
             :aria-valuemax="max">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "ComponentProgress",
    computed: {
        striped() {
            return {
                'progress-bar-striped': this.$props.isStriped === true
            }
        },
        animated() {
            return {
                'progress-bar-animated': this.$props.isAnimated === true
            }
        },
        width() {
            return (this.$props.now / this.$props.max) * 100;
        }
    },
    props: {
        now: {
            type: Number,
            required: true
        },
        min: {
            type: Number,
            required: false,
            default: 0
        },
        max: {
            type: Number,
            required: false,
            default: 100
        },
        color: {
            type: String,
            default: '',
            required: false,
            validator: value => {
                return ['bg-success', 'bg-info', 'bg-warning', 'bg-danger', ''].indexOf(value) !== -1
            }
        },
        height: {
            type: [Boolean, String],
            required: false,
            default: false
        },
        isStriped: {
            type: Boolean,
            required: false,
            default: false
        },
        isAnimated: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style scoped>

</style>