<template>
    <layout-full-width title="Portokasse">

        <div class="row"  v-if="!loading">
            <div class="col-12 mb-3">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <strong>{{ $tc('customer-account') }}</strong>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="onSignOut">
                            <div class="form-group">
                                <label for="email">{{ $tc('email') }}</label>
                                <input id="email" class="form-control" type="email" v-model="email" disabled>
                            </div>

                            <div class="form-group">
                                <label for="password">{{ $tc('password') }}</label>
                                <input id="password" class="form-control" type="password" v-model="password" disabled>
                            </div>
                            <button class="btn btn-primary" type="submit">{{ $tc('sign-out') }}</button>
                        </form>
                    </div>
                </div>
            </div>
            <div id="balance-deck" class="col-12 card-deck mb-3 mx-0">
                <div class="card mx-0 shadow-sm mb-3 mb-lg-0">
                    <div class="card-header">
                        <strong>{{ $tc('portokasse-wallet') }}</strong>
                    </div>
                    <div class="card-body text-dark d-flex justify-content-center align-items-center">
                        <p class="h1 mt-n3 mb-3"><strong>{{ walletAmount | currency_2 }}</strong></p>
                    </div>
                </div>
                <div class="card mx-0 mx-lg-3 shadow-sm mb-3 mb-lg-0">
                    <div class="card-header">
                        <strong>{{ $tc('manual-top-up') }}</strong>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="onManualTopUp">
                            <div class="form-group">
                                <label for="manual-amount">{{ $tc('amount') }}</label>
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">€</div>
                                    </div>
                                    <input id="manual-amount"
                                           class="form-control"
                                           type="number"
                                           min="10"
                                           step="10"
                                           v-model="manualTopUp.amount.userInput">
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="submit">{{ $tc('top-up') }}</button>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-info d-flex mt-3" role="alert">
                                <div class="col-auto align-self-center">
                                    <font-awesome-icon size="lg" icon="info"/>
                                </div>
                                <div class="col">
                                    <p class="m-0">You will be redirected to the website of the Deutsche Post to finish the transaction.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card mx-0 shadow-sm">
                    <div class="card-header">
                        <strong>{{ $tc('one-click-top-up') }}</strong>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="onManualDropdownTopUp">
                            <div class="form-group">
                                <label for="manual-amount-dropdows">{{ $tc('amount') }}</label>
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">€</div>
                                    </div>
                                    <select id="manual-amount-adropdows"
                                           class="form-control"
                                           type="number"
                                           min="10"
                                           step="10"
                                           v-model="manualTopUp.amount.dropdown">
                                        <option value="5000">{{ 50 | currency }}</option>
                                        <option value="10000">{{ 100 | currency }}</option>
                                        <option value="25000">{{ 250 | currency }}</option>
                                        <option value="50000">{{ 500 | currency }}</option>
                                        <option value="100000">{{ 1000 | currency }}</option>
                                        <option value="250000">{{ 2500 | currency }}</option>
                                        <option value="500000">{{ 5000 | currency }}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="submit">{{ $tc('top-up') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-info d-flex mt-3" role="alert">
                                <div class="col-auto align-self-center">
                                    <font-awesome-icon size="lg" icon="info"/>
                                </div>
                                <div class="col">
                                    <p class="m-0">The amount will be debited from your stored SEPA direct debit client.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <div class="col-12 mb-3">
                <div class="card shadow-sm">

                    <div class="card-header">
                        <strong>{{ $tc('automated-top-up') }}</strong>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="onAutomatedTopUp">

                            <div class="form-group">
                                <label for="automated-enabled">{{ $tc('enabled') }}</label>
                                <select id="automated-enabled" class="form-control" v-model="automatedTopUp.enabled">
                                    <option :value="false">{{ $tc('no') }}</option>
                                    <option value="yes-on-demand">{{ $tc('yes') }}, {{ $tc('on-demand') }}</option>
                                    <option value="yes-on-threshold">{{ $tc('yes') }}, {{ $tc('on-threshold') }}</option>
                                </select>
                            </div>

                            <div class="form-group" v-if="automatedTopUp.enabled === 'yes-on-demand'">
                                <label for="automated-on-demand-check">{{ $tc('confirmation-needed') }}?</label>
                                <div class="form-group">
                                    <div class="form-check">
                                        <input id="automated-on-demand-check"
                                               class="form-check-input"
                                               type="checkbox">
                                        <label class="form-check-label" for="automated-on-demand-check">
                                            Display a confirmation pop-up in the frontend, asking the user whether to proceed with wallet the top-up or not.
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" v-if="automatedTopUp.enabled === 'yes-on-threshold'">
                                <label for="automated-threshold-amount">Threshold amount</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">€</div>
                                    </div>
                                    <input id="automated-threshold-amount"
                                           class="form-control"
                                           type="number"
                                           step="10"
                                           v-model="automatedTopUp.thresholdAmount"/>
                                </div>
                            </div>

                            <div class="form-group" v-if="automatedTopUp.enabled !== false">
                                <label for="automated-multiplier">Top-Up Amount</label>
                                <select id="automated-multiplier"
                                        class="form-control"
                                        v-model="automatedTopUp.multiplier">
                                    <option value="50">{{ 50 | currency }}</option>
                                    <option value="100">{{ 100 | currency }}</option>
                                    <option value="250">{{ 250 | currency }}</option>
                                    <option value="500">{{ 500 | currency }}</option>
                                </select>
                            </div>

                            <div class="table-responsive-lg" v-if="automatedTopUp.enabled !== false">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Limit</th>
                                        <th>Top-Up Amount</th>
                                        <th style="min-width: 150px">% of limit reached</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="align-middle auto-top-up-frequency">{{ $tc('daily') }}</td>
                                            <td class="align-middle auto-top-up-limit">
                                                <div class="form-group mb-0">
                                                    <label for="limit-daily" class="sr-only">
                                                        {{ $tc('daily-limit') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">€</div>
                                                        </div>
                                                        <select id="limit-daily"
                                                                class="form-control"
                                                                v-model="limit.day.amount">
                                                            <option value="50">{{ 50 | currency }}</option>
                                                            <option value="100">{{ 100 | currency }}</option>
                                                            <option value="250">{{ 250 | currency }}</option>
                                                            <option value="500">{{ 500 | currency }}</option>
                                                            <option value="1000">{{ 1000 | currency }}</option>
                                                            <option value="2500">{{ 2500 | currency }}</option>
                                                            <option value="5000">{{ 5000 | currency }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="form-group mb-0">
                                                    <label for="limit-daily-used" class="sr-only">
                                                        {{ $tc('used-daily-limit') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">€</div>
                                                        </div>
                                                        <input id="limit-daily-used"
                                                               class="form-control"
                                                               type="text"
                                                               min="10"
                                                               step="1"
                                                               disabled="disabled"
                                                               v-model="limit.day.used">
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <component-progress :now="limit.day.used"
                                                                    :max="limit.day.amount">
                                                    {{ (limit.day.used / limit.day.amount * 100).toFixed(2) }}%
                                                </component-progress>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="align-middle  auto-top-up-frequency">{{ $tc('weekly') }}</td>
                                            <td class="align-middle auto-top-up-limit">
                                                <div class="form-group mb-0">
                                                    <label for="limit-weekly" class="sr-only">
                                                        {{ $tc('weekly-limit') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">€</div>
                                                        </div>
                                                        <select id="limit-weekly"
                                                                class="form-control"
                                                                v-model="limit.week.amount">
                                                            <option value="50">{{ 50 | currency }}</option>
                                                            <option value="100">{{ 100 | currency }}</option>
                                                            <option value="250">{{ 250 | currency }}</option>
                                                            <option value="500">{{ 500 | currency }}</option>
                                                            <option value="1000">{{ 1000 | currency }}</option>
                                                            <option value="2500">{{ 2500 | currency }}</option>
                                                            <option value="5000">{{ 5000 | currency }}</option>
                                                            <option value="10000">{{ 10000 | currency }}</option>
                                                            <option value="25000">{{ 25000 | currency }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="form-group mb-0">
                                                    <label for="limit-weekly-used" class="sr-only">
                                                        {{ $tc('used-weekly-limit') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">€</div>
                                                        </div>
                                                        <input id="limit-weekly-used"
                                                               class="form-control"
                                                               type="text"
                                                               min="10"
                                                               step="1"
                                                               disabled="disabled"
                                                               v-model="limit.week.used">
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <component-progress :now="limit.week.used"
                                                                    :max="limit.week.amount">
                                                    {{ (limit.week.used / limit.week.amount * 100).toFixed(2) }}%
                                                </component-progress>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="align-middle auto-top-up-frequency">{{ $tc('monthly') }}</td>
                                            <td class="align-middle auto-top-up-limit">
                                                <div class="form-group mb-0">
                                                    <label for="limit-monthly" class="sr-only">
                                                        {{ $tc('monthly-limit') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">€</div>
                                                        </div>
                                                        <select id="limit-monthly"
                                                                class="form-control"
                                                                v-model="limit.month.amount">
                                                            <option value="50">{{ 50 | currency }}</option>
                                                            <option value="100">{{ 100 | currency }}</option>
                                                            <option value="250">{{ 250 | currency }}</option>
                                                            <option value="500">{{ 500 | currency }}</option>
                                                            <option value="1000">{{ 1000 | currency }}</option>
                                                            <option value="2500">{{ 2500 | currency }}</option>
                                                            <option value="5000">{{ 5000 | currency }}</option>
                                                            <option value="10000">{{ 10000 | currency }}</option>
                                                            <option value="25000">{{ 25000 | currency }}</option>
                                                            <option value="50000">{{ 50000 | currency }}</option>
                                                            <option value="100000">{{ 100000 | currency }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="form-group mb-0">
                                                    <label for="limit-monthly-used" class="sr-only">
                                                        {{ $tc('used-monthly-limit') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">€</div>
                                                        </div>
                                                        <input id="limit-monthly-used"
                                                               class="form-control"
                                                               type="text"
                                                               min="10"
                                                               step="1"
                                                               disabled="disabled"
                                                               v-model="limit.month.used">
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <component-progress :now="limit.month.used"
                                                                    :max="limit.month.amount">
                                                    {{ (limit.month.used / limit.month.amount * 100).toFixed(2) }}%
                                                </component-progress>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <button class="btn btn-primary mt-1 mt-md-0" type="submit">{{ $tc('save') }}</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading">
            <component-spinner class="p-5" type="spinner-grow"/>
        </div>
    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentProgress from "@/components/ui/ComponentProgress";
import ComponentSpinner from "@/components/ui/ComponentSpinner";
export default {
    name: "PagePortokasse",
    components: {ComponentProgress, LayoutFullWidth, ComponentSpinner },
    data() {
        return {
            loading: true,
            email: 'pcf_00239@zq4nnzgbnbvt3.webpage.t-com.de',
            password: 'XQ6sLsaA3',
            manualTopUp: {
                amount: {
                    userInput: 50,
                    dropdown: 5000
                }
            },
            automatedTopUp: {
                enabled: 'yes-on-demand',
                multiplier: 50,
                onDemandCheck: false,
                thresholdAmount: 100
            },
            limit: {
                day: {
                    amount: 50,
                    used: 50
                },
                week: {
                    amount: 250,
                    used: 150
                },
                month: {
                    amount: 2500,
                    used: 600
                }
            }
        }
    },
    computed: {
        walletAmount: function () {
            return this.$store.state.forkLane.dpdhl.portokasse.wallet / 100
        }
    },
    async beforeMount() {
        await this.$store.dispatch('forkLane/loginDeutschePostUser');
        this.loading = false;
    },
    methods: {
        onLogin() {
        },
        onSignOut() {
            const payload = {
                title: 'Signed Out Successfully',
                message: 'You successfully signed out',
                duration: -1
            }
            this.$store.dispatch('toast/success', payload)
        },
        onManualTopUp() {
        },
        async onManualDropdownTopUp() {
            this.loading = true;

            await this.$store.dispatch('forkLane/loginDeutschePostUser');

            const payload = {
                authToken: this.$store.state.forkLane.dpdhl.portokasse.token,
                balance: this.$store.state.forkLane.dpdhl.portokasse.wallet,
                amountInCent: this.manualTopUp.amount.dropdown
            }

            await this.$store.dispatch('forkLane/topUpDetuschePost', payload);

            this.loading = false;
        },
        onAutomatedTopUp() {
        }
    }
}
</script>

<style scoped lang="scss">

    .table {
        td {
            min-width: 120px;
        }

        .top-up-frequency {
            min-width: 60px;
        }

        .auto-top-up-limit {
            min-width: 168px;
        }
    }


    @media (max-width: 992px) {
        #balance-deck {
            flex-direction: column;
        }
    }
</style>